<template>
    <div class="detail-main" >
        <div class="detail-head flex">
            <div class="head-left">
                <p class="left-txt flex">
                    <span class="lable">{{$t("followUp.bindfollow")}}：</span>
                    <span class="value">{{detail.userName}}</span>
                </p>
                <p class="left-txt flex">
                    <span class="lable">{{$t("followUp.followname")}}：</span>
                    <span class="value">{{detail.followUpTitle}}</span>
                </p>
                <p class="left-time flex">
                    <span class="lable">{{$t("followUp.startTime")}}：</span>
                    <span class="value">{{getLocalDateTime(detail.startTime)}}</span>
                </p>
            </div>
            <div class="head-right">
                <my-circle :percentNum="detail.cricle" :width="'3rem'" :circleColor="!detail.isStart ? '#BFBFBF': !detail.isEnd ?  '#FF671C': '#0095FF'" :circleback="!detail.isStart ? '#E4E5E8': !detail.isEnd ?  '#FFE9DE': '#0095FF'">
                    <div class="schedule flex"  slot="container" :style="{'--color':!detail.isStart ? '#BFBFBF': !detail.isEnd ?  '#FF671C': '#0095FF'}">
                        <p class="txt" >{{$t("followUp.schedule")}}</p>
                        <p class="circleNum">{{detail.cricle}}%</p>
                    </div>
                </my-circle>
            </div>
        </div>
        <div class="progress-bar">
            <div class="bar-item flex" v-for="(item,index) in detail.nodes" :class="{'itemActive':item.followUpNodeId != 0}" :key="index">
                <div class="item-left-line">
                    <span>{{index+1}}</span>
                </div>
                <div class="item-right-container">
                    <p class="time" :class="{'today':todayFun(item)}"> <span v-if="todayFun(item)">{{$t("followUp.today")}}</span> {{getLocalDateTime(item.cdt)}} {{getweekday(item.cdt)}}</p>
                    <div class="container-details" v-for="(citem,index) in item.actions" :key="index"  :class="citem.nowCurrent? 'nowCurrent': citem.followUpNodeActionId != 0 ? 'executed':''" @click="goDetails(citem)">
                        
                        <img src="@/assets/img/follow-status-icon1.png" alt="123" v-if="citem.followUpNodeActionId != 0&&(citem.pushObjType == '104002'?citem.isOpened == 0: citem.pushObjType == '104002'?citem.isSubmitted == 0:false )" class="status-icon" >
                        <img src="@/assets/img/follow-status-icon2.png" alt="sdf" v-else-if="citem.followUpNodeActionId != 0&&(citem.pushObjType == '104002'?citem.isOpened==1:citem.pushObjType == '104004'?citem.isSubmitted == 1:false)" class="status-icon" >

                        <img src="@/assets/img/follow-status-icon2.png" alt="456" v-else-if="citem.pushObjType == '104013'&&citem.followUpNodeActionId != 0" class="status-icon">
                        <img src="@/assets/img/follow-status-icon1.png" v-else-if="citem.followUpNodeActionId != 0" class="status-icon">
                        <div class="details flex">
                            <p class="lable flex">
                                <img src="@/assets/img/follow-time-icon-active.png" v-if="citem.nowCurrent" alt="">
                                <img src="@/assets/img/follow-time-icon.png" v-else alt="">
                                <span class="txt">{{$t("followUp.time")}}：</span>
                            </p>
                            <p class="value">{{citem.exeTime}}</p>
                        </div>
                        <div class="details flex">
                            <p class="lable flex">
                                <img src="@/assets/img/follow-type3-active.png" v-if="citem.nowCurrent" alt="">
                                <img src="@/assets/img/follow-type3.png" v-else alt="">
                                <span class="txt">{{citem.pushObjType == 104002?$t("followUp.article"):citem.pushObjType == 104013?$t("followUp.remind"):$t("followUp.questionnaire")}}：</span>
                            </p>
                            <p class="value">{{citem.pushText}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="bar-item itemActive flex">
                <div class="item-left-line">
                    <span>1</span>
                </div>
                <div class="item-right-container">
                    <p class="time">2021年8月3日 星期二</p>
                    <div class="container-details">
                        <img src="@/assets/img/follow-status-icon2.png" class="status-icon" alt="">
                        <div class="details flex">
                            <p class="lable flex">
                                <img src="@/assets/img/follow-time-icon.png" alt="">
                                <span class="txt">{{$t("followUp.time")}}：</span>
                            </p>
                            <p class="value">10:30</p>
                        </div>
                        <div class="details flex">
                            <p class="lable flex">
                                <img src="@/assets/img/follow-type3.png" alt="">
                                <span class="txt">提醒：</span>
                            </p>
                            <p class="value">到点该吃药了</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import myCircle from "@/components/my_circle"
import { followupGet } from "@/api/index";
import { getLocalDateTime, getweekday } from "@/utils/date";

export default {
    components:{
        myCircle
    },
    data () {
        return {
            detail:{},
            getLocalDateTime,
            getweekday
        }
    },
    created(){
        this.getDetails()
        document.title = this.$t("followUp.followDetails")
    },
    methods: {
        todayFun(item){
            // todayFun
            console.log((new Date().getFullYear()+new Date().getMonth()+new Date().getDate()) == new Date(item.cdt).getFullYear() + new Date(item.cdt).getMonth() + new Date(item.cdt).getDate());
            return (new Date().getFullYear()+new Date().getMonth()+new Date().getDate()) == new Date(item.cdt).getFullYear() + new Date(item.cdt).getMonth() + new Date(item.cdt).getDate();
        },
        goDetails(item){
            if(item.followUpNodeActionId!=0){
                if(item.pushObjType == 104002){
                    this.$router.push({
                        path:"/article/detail",
                        query:{
                            materialId:item.pushObjId,
                            __objType__:104002,
                            __objId__:item.pushObjId,
                            _followup_node_action_id_:item.followUpNodeActionId,
                        }
                    })
                }else if(item.pushObjType == 104004){
                    this.$router.push({
                        path:"/questionaire/go-questionaire",
                        query:{
                            questionaireDefId:item.pushObjId,
                            __objType__:104004,
                            __objId__:item.pushObjId,
                            _followup_node_action_id_:item.followUpNodeActionId,
                        }
                    })
                }else if(item.pushObjType == 104013){
                     this.$router.push({
                        path:"/followup/notice-detail",
                        query:{
                            followupNodeActionId:item.pushObjId,
                            __objType__: 104007,
                            __objId__:item.pushObjId,
                            _followup_node_action_id_:item.followUpNodeActionId,
                        }
                    })
                }
            }
        },
        getDetails(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            followupGet({
                followUpId:this.$route.query.followUpId
            }).then(res =>{
                toast.close();
                let nodeTime = ''; 
                res.data.data.isStart = false;
                res.data.data.isEnd = false;
                res.data.data.cricle = 0;
                res.data.data.startNum = 0;
                res.data.data.startTime = res.data.data.cdt;
                nodeTime = res.data.data.cdt;
                let current = false;
                for (let g = 0; g < res.data.data.nodes.length; g++) {                    
                    if(res.data.data.nodes[g].followUpNodeId != 0){
                        res.data.data.startNum ++;
                        res.data.data.isStart = true;                        
                    }
                    if(g==0){
                        res.data.data.startTime = res.data.data.cdt + ((res.data.data.nodes[g].nday-1) * 60 * 60 * 24 * 1000);
                    }
                    // nodeTime = (res.data.data.nodes[g].nday * 60 * 60 * 24 * 1000);
                    res.data.data.nodes[g].cdt = res.data.data.cdt + ((res.data.data.nodes[g].nday-1) * 60 * 60 * 24 * 1000);
                    if(!current){
                        for (let j = 0; j < res.data.data.nodes[g].actions.length; j++) {
                            if(!current){
                                if(res.data.data.nodes[g].actions[j].followUpNodeActionId == 0 && j == res.data.data.nodes[g].actions.length-1){
                                    current = true;
                                    res.data.data.nodes[g].actions[j].nowCurrent = true;
                                }else{
                                    if(res.data.data.nodes[g].actions[j].followUpNodeActionId == 0&&res.data.data.nodes[g].actions[j+1].followUpNodeActionId == 0){
                                        current = true;
                                        res.data.data.nodes[g].actions[j].nowCurrent = true;
                                    }
                                }
                            }
                        }
                    }
                }

                res.data.data.cricle = parseInt(res.data.data.startNum / res.data.data.nodes.length * 100);
                if(res.data.data.startNum == res.data.data.nodes.length){
                    res.data.data.isEnd = true;
                }
                this.detail = res.data.data;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.detail-main{
    min-height: 100vh;
    background-color: #F5F6FA;
    box-sizing: border-box;
    padding-top: 0.2rem;
    .detail-head{
        padding: 0.6rem;
        background: #fff;
        justify-content: space-between;
        align-items: center;
        .head-left{
            margin-right: 0.4rem;
            .left-txt{
                margin-bottom: 0.5rem;
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #333333;
                .lable{
                    flex: 0 0 auto;
                }
                
            }
            .left-time{
                font-size: 0.56rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                .lable{
                    flex: 0 0 auto;
                }
            }
        }
        .head-right{
            .schedule{
                flex-direction: column;
                align-items: center;
                .txt{
                    font-size: 0.52rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
                .circleNum{
                    font-size: 0.76rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }
    }
    .progress-bar{
        padding: 0.6rem;
        .bar-item{
            // padding-left: 1.2rem;
            padding-bottom: 1rem;
            align-items: flex-start;
            position: relative;
            
            &:last-child{
                padding-bottom: 0;
            }
            &::after{
                content: ' ';
                position: absolute;
                height: 100%;
                top: 0;
                left: 0.58rem;
                border-left:0.04rem solid #BFBFBF;
                z-index: 1;
            }
            .item-left-line{
                flex: 0 0 auto;
                width: 1.2rem;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                span{
                    position: relative;
                    z-index: 9;
                    width: 0.6rem;
                    height: 0.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    border-radius: 50%;
                    color: #FFFFFF;
                    background: #BFBFBF;
                }
            }
            .item-right-container{
                flex: 1;
                .today{
                    font-weight: 500;
                    color: #FF671C !important;
                    span{
                        font-size: 0.72rem;
                    }
                }
                .time{
                    font-size: 0.56rem;
                    line-height: 0.6rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
                .container-details{
                    padding: 0.6rem;
                    background: #ECEEF3;
                    box-shadow: 0px 0.12rem 0.3rem 0px rgba(197, 215, 232, 0.25);
                    border-radius: 0.16rem;
                    margin-top: 0.4rem;
                    position: relative;
                    .status-icon{
                        width: 1.88rem;
                        height: 1.88rem;
                        position: absolute;
                        right: 0.6rem;
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    .details{
                        align-items: flex-start;
                        margin-bottom: 0.4rem;
                        &:last-child{
                            margin: 0;
                        }
                        .lable{
                            flex: 0 0 auto;
                            align-items: center;
                            img{
                                height: auto;
                                width: 0.48rem;
                                margin-right: 0.3rem;
                            }
                            .txt{
                                font-size: 0.56rem;
                                font-family: PingFang SC;
                                font-weight: 400;
                                color: #666666;
                            }
                        }
                        .value{
                            font-size: 0.56rem;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #666666;
                        }
                    }  
                }
                .executed{
                    background: #fff;
                }
                .nowCurrent{
                    background: #3387FD;
                    p,span{
                        color: #fff !important;
                    }
                }
            }
        }
        .itemActive{
            &::after{
                border-color: #3387FD;
            }
            .item-left-line{
                span{
                    background: #3387FD;
                }
            }
        }
    }
}
</style>